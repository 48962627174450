import React from "react"
import Layout from "../../components/layout"
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const ThankYou = () => {
  return (
    <Layout>
      <section
        className={`cover cover-main cover-img cover-full-top cover-md`}
        style={{
          background: `url(https://www.sinarmasland.com/app/uploads/2024/03/Foto-2-Gedung-GOP-9.jpeg) no-repeat center`,
          marginTop: -80,
        }}
      >
        <div
          className={`container text-white text-center flex-column align-items-center justify-content-center`}
          style={{
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative", zIndex: 1 }}>
            <p className="font-weight-bold" style={{ fontSize: 30 }}>
              Thank you!
            </p>
            <p style={{ fontSize: 20 }}>
              We have received your form, and we will contact you soon.
            </p>
            <p>
              <a href="tel:+6221-5315 9000">021 5315 9000</a>
            </p>
          </div>
        </div>
        <div className="bg-overlay bg-overlay-70"></div>
      </section>
    </Layout>
  )
}

export default ThankYou
